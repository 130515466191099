@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.relative{
  position: relative;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #000;
  min-height: 100vh;
  height: max-content;
}
span {
  color: #ffffffc1;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  line-height: 2.5;
}
input {
  outline: none;
}
.content_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.content_box > h1 {
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.content_box > h4 {
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.content_box > form {
  margin-top: 30px;
  padding: 15px;
  width: 50%;
}
.content_box > form > div > input {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-size: 20px;
  width: 100%;
  height: 50px;
  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.submit_btn {
  cursor: pointer;
  width: 160px !important;
  height: 45px;
  background: #fff !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: #111 !important;
  margin-left: 0px !important;
}
.content_box > form > div > p {
  color: #ffffffb6;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 1.5;
}

/* media queries */
@media (max-width: 768px) {
  .content_box > form {
    width: 100% !important;
    padding: 0 !important;
  }
  .content_box > form > div > input {
    margin-left: 0px !important;
    width: 95%;
  }
  .submit_btn {
    width: 98% !important;
  }
  .content_box > form > div {
    padding: 0px 12px;
  }
  .loader {
   width: 25px!important;
  }
}
.loader {
  border: 5px solid #fff;
  border-radius: 100%;
  border-top: 5px solid transparent;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: absolute;
  z-index: 1111;
  top: 23%;
  left: 42%;
  /* background: #fff; */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}